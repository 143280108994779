<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <select-romaneio class="mr-2"></select-romaneio>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <v-text-field
          class="mr-2"
          label="Funcionário"
          name="Funcionario"
          placeholder="Funcionário"
          v-model="funcionario"
          :readonly="true"
        ></v-text-field>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <select-vendedor class="mr-2"></select-vendedor>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-menu
            class="pr-2"
            ref="dtSaida"
            lazy
            :close-on-content-click="false"
            v-model="dtNtSaida"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataSaida"
          >
            <v-text-field
              slot="activator"
              label="Data saída"
              v-model="dataSaida"
              :rules="dataRetornoRules"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataSaida" no-title scrollable locale="pt-br">
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dtNtSaida = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.dtSaida.save(dataSaida)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-menu
            class="pr-2"
            ref="dtReturn"
            lazy
            :close-on-content-click="false"
            v-model="dtPrevReturn"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataRetorno"
          >
            <v-text-field
              slot="activator"
              label="Data retorno"
              v-model="dataRetorno"
              :rules="dataRetornoRules"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataRetorno" no-title scrollable :min="dataSaida" locale="pt-br">
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dtPrevReturn = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.dtReturn.save(dataRetorno)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-text-field
            class="mr-2"
            label="Praça"
            name="Praca"
            placeholder="Praça"
            v-model="praca"
            :readonly="true"
          ></v-text-field>
        </div>
      </v-flex>
      <v-divider></v-divider>      
      <v-flex lg12 sm12 xs12>
        <div class="d-flex" :readonly="codRomaneio == ''">
          <select-produto class="mr-2"></select-produto>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-data-table :headers="headers" :items="produtos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td style="display:none;">{{ props.item.id }}</td>
              <td style="display:none;">{{ props.item.idProduto }}</td>
              <td>{{ props.item.referencia }}</td>
              <td>{{ props.item.nomeProduto }}</td>
              <td>{{ props.item.precoVenda }}</td>
              <td>{{ props.item.semComissao ? 'SIM' : 'NÃO' }}</td>
              <td>
                <v-text-field
                  style="width:80px"
                  type="number"
                  name="quantidade"
                  v-model="props.item.quantidade"
                  required
                  :readonly="props.item.id != 0"
                ></v-text-field>
              </td>
            </template>
          </v-data-table>
        </div>
      </v-flex>
    </v-layout>
    <div class="form-btn">
      <v-btn @click="voltar" outline>Voltar</v-btn>
      <v-btn v-if="!bloqueado" outline @click="submit" color="primary">Salvar</v-btn>
    </div>
    <div>
      <progress-circular></progress-circular>
    </div>
    <v-snackbar :color="'error'" v-model="snackbar" :top="true">
      {{ text }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import SelectRomaneio from "../romaneio/selectRomaneio.vue"
import SelectVendedor from "../vendedor/selectVendedor"
import ProgressCircular from "../progressCircular/progressCircular"
import NotaSaidaComProdutos from "../../domain/saida/NotaSaidaComProdutos"
import { ServicoSaida } from "../../servicos/servicoSaida"
import SelectProduto from "../produto/select/selectProdutos.vue"
const servicoSaida = new ServicoSaida()
export default {
  components: {
    SelectRomaneio,
    SelectVendedor,
    SelectProduto,
    ProgressCircular
  },
  data() {
    return {
      bloqueado: true,
      romaneios: [],
      codRomaneio: "",
      codPraca: "",
      vendedores: "",
      codVendedor: "",
      praca: "",
      dtNtSaida: false,
      dataSaida: null,
      dtPrevReturn: false,
      dataRetorno: null,
      funcionario: "",
      codFuncionario: "",
      codigoBarra: "",
      produtos: [],
      snackbar: false,
      text: "",
      headers: [
        { text: "Referência", align: "left", sortable: false, value: "quantidade" },
        { text: "Nome", align: "left", sortable: false, value: "referenciaInformada" },
        { text: "Preço", align: "left", sortable: false, value: "dataGeracao" },
        { text: "Sem Comissão", align: "left", sortable: false, value: "semComissao" },
        { text: "Quantidade", align: "left", sortable: false }
      ],
      dataSaidaRules: [v => !!v || "Campo obrigatório"],
      dataRetornoRules: [v => !!v || "Campo obrigatório"]
    }
  },
  created() {
    serverBus.$on("produtosSelecionados", value => {
      this.produtos = value
    })
    serverBus.$on("romaneioSelected", value => {
      this.codPraca = value.IdPraca
      this.codRomaneio = value.Id
      this.codFuncionario = value.IdFuncionario
      this.funcionario = value.Funcionario      
      serverBus.$emit("buscarProdutoPorRomaneio", value.Id)
    })
    serverBus.$on("vendedorSelected", value => {
      if (value.situacao != 1) {
        this.bloqueado = true
      } else {
        this.praca = value.praca
        this.codVendedor = value.id
        this.bloqueado = false
      }

      if (value.situacao == 2) {
        this.snackbar = true
        this.text =
          "Vendedor bloqueado por falta de pagamento, consulte o valor da divida no cadastro ou entre em contato com a empresa."
      } else if (value.situacao == 3) {
        this.snackbar = true
        this.text = "Vendedor bloqueado por falta de devolução de mercadoria, entre em contato com a empresa."
      } else if (value.situacao == 4) {
        this.snackbar = true
        this.text = "Vendedor bloqueado por CPF negativado no SPC, entre em contato com a empresa."
      }
    })
  },
  methods: {
    submit() {
      this.abrirProgressCircular()
      if (this.$refs.form.validate()) {
        if (this.produtos.length == 0) {
          this.fecharProgressCircular()
          alert("Não é possível salvar uma saída sem produtos vinculados.")
          return false
        }

        var notaSaida = new NotaSaidaComProdutos()
        notaSaida.IdRomaneio = this.codRomaneio
        notaSaida.IdVendedor = this.codVendedor
        notaSaida.DataSaida = this.dataSaida
        notaSaida.DataRetorno = this.dataRetorno
        notaSaida.Produtos = []

        this.produtos.forEach(element => {
          var produto = new Object()
          produto.id = element.id != undefined ? parseInt(element.id) : 0
          produto.idProduto = parseInt(element.idProduto)
          produto.quantidade = parseInt(element.quantidade)
          produto.precoVenda = parseFloat(element.precoVenda)
          produto.semComissao = parseFloat(element.semComissao ? 1 : 0)
          notaSaida.Produtos.push(produto)
        })

        servicoSaida
          .salvar(notaSaida)
          .then(res => {
            if (res.status === 200 && res.data.length === undefined) {
              this.fecharProgressCircular()
              this.$router.push({ name: "saidaConsignada" })
            } else {
              this.fecharProgressCircular()
              this.snackbar = true
              this.text = res.data[0].title + " - " + res.data[0].detail
            }
          })
          .catch(error => {
            this.fecharProgressCircular()
            this.snackbar = true
            this.text = "Erro ao salvar uma nova saída " + error
            return Promise.reject(error)
          })
      }
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    },
    voltar() {
      this.$router.push({ name: "saidaConsignada" })
    }
  }
}
</script>
<style scoped>
a.nounderline:link {
  text-decoration: none;
}
</style>
